import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react';

import Keycloak from 'keycloak-js';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useAuth } from '@/hooks/useAuth';
import { useLocalStorage } from '@/hooks/useLocalStorage';

import { KEYCLOAK_URL } from '@/utils/constants';

export const keycloak = new Keycloak({
  url: KEYCLOAK_URL,
  realm: 'wta',
  clientId: 'fantasy-web',
});

export const KeycloakContext = createContext(keycloak);

export const KeycloakProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setItem: setKcToken, value: tokenFromStore } =
    useLocalStorage<string>(StorageKeysEnum.kcToken);
  const { setItem: setKcRefreshToken, value: refreshTokenFromStore } =
    useLocalStorage<string>(StorageKeysEnum.kcRefreshToken);
  const { checkAuth } = useAuth();

  const saveTokens = () => {
    if (keycloak.token) {
      setKcToken(keycloak.token);
      setKcRefreshToken(keycloak.refreshToken || '');
    }
  };

  useEffect(() => {
    keycloak
      .init({
        checkLoginIframe: false,
        token: tokenFromStore,
        refreshToken: refreshTokenFromStore,
        timeSkew: 0,
      })
      .then((auth) => {
        if (auth) {
          saveTokens();
          //   Check auth in users service
          checkAuth();
        }
      })
      .catch(() => console.error('Keycloak init error'));

    keycloak.onTokenExpired = () => {
      keycloak
        .updateToken()
        .then(() => {
          localStorage.setItem(StorageKeysEnum.kcToken, String(keycloak.token));
          localStorage.setItem(
            StorageKeysEnum.kcRefreshToken,
            String(keycloak.refreshToken),
          );
        })
        .catch(() => {
          // If authentication unsuccessful, remove expired tokens
          localStorage.removeItem(StorageKeysEnum.kcToken);
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KeycloakContext.Provider value={keycloak}>
      {children}
    </KeycloakContext.Provider>
  );
};

export const useKeycloak = () => {
  const context = useContext(KeycloakContext);
  if (context === undefined) {
    throw new Error('useKeycloak must be used within a KeycloakProvider');
  }

  return context;
};
