import React, { lazy } from 'react';

import { RoutesEnum } from '@/enum/routes.enum';

const UnsubscribePage = lazy(() => import('@/pages/Unsubscribe'));
const HomePage = lazy(() => import('@/pages/Home'));
const SSOEntryPage = lazy(() => import('@/pages/SSOEntry'));
const RedirectCallbackPage = lazy(() => import('@/pages/RedirectCallback'));

interface IRoutes {
  path: RoutesEnum;
  element: React.ReactNode;
  children?: IRoutes[];
}

export const AppRoutes: IRoutes[] = [
  {
    path: RoutesEnum.UNSUB,
    element: <UnsubscribePage />,
  },
  {
    path: RoutesEnum.HOME,
    element: <HomePage />,
  },

  {
    path: RoutesEnum.SSOENTRY,
    element: <SSOEntryPage />,
  },
  {
    path: RoutesEnum.REDIRECT_CALLBACK,
    element: <RedirectCallbackPage />,
  },
];
