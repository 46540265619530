import { useMemo } from 'react';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { enabledLanguages } from '@/localization';

import { Logo } from '@/components/Logo';

import { useSidebarContext } from '@/context/sidebar.context';

import LangSwitcher from '@/elements/LangSwitcher';

import { RoutesEnum } from '@/enum/routes.enum';

import { LoginAndLogOut } from '../LoginAndLogOut';
import { ProfileButton } from '../components/ProfileButton';

import { MarketingForm } from './MarketingForm';

export const Sidebar = () => {
  const { isSidebarOpen, onSidebarClose } = useSidebarContext();
  const theme = useTheme();
  const navigate = useNavigate();

  const goToHome = () => {
    onSidebarClose();
    navigate(RoutesEnum.HOME);
  };

  const isLangSwitcherEnabled = useMemo(() => enabledLanguages.length > 1, []);

  return (
    <Drawer isOpen={isSidebarOpen} onClose={onSidebarClose} placement='left'>
      <DrawerOverlay />
      <DrawerContent backgroundColor={theme.colors.sidebar.bg}>
        <DrawerCloseButton color={theme.colors.sidebar.close} />
        <DrawerHeader pt='50px'>
          <Button onClick={goToHome} variant='ghost'>
            <Logo />
          </Button>
        </DrawerHeader>

        <DrawerBody>
          {isLangSwitcherEnabled && (
            <Box maxW='100px' mb='20px' mr='auto' pt='20px' w='100%'>
              <LangSwitcher />
            </Box>
          )}

          <Grid gap='16px'>
            {/*<GameRulesButton />*/}
            {/*<Flex align='center' cursor='pointer' gap='8px'>*/}
            {/*  <svg*/}
            {/*    fill='none'*/}
            {/*    height='32'*/}
            {/*    viewBox='0 0 32 32'*/}
            {/*    width='32'*/}
            {/*    xmlns='http://www.w3.org/2000/svg'*/}
            {/*  >*/}
            {/*    <path*/}
            {/*      d='M15.6082 24.9287C16.1289 24.4088 16.1289 23.5659 15.6082 23.046L9.88432 17.3312H25.332C26.0684 17.3312 26.6654 16.7351 26.6654 15.9999C26.6654 15.2647 26.0684 14.6687 25.332 14.6687H9.88432L15.6082 8.95381C16.1289 8.43393 16.1289 7.59104 15.6082 7.07116C15.0875 6.55127 14.2433 6.55127 13.7226 7.07116L5.72256 15.0586C5.47251 15.3083 5.33203 15.6469 5.33203 15.9999C5.33203 16.353 5.47251 16.6916 5.72256 16.9413L13.7226 24.9287C14.2433 25.4486 15.0875 25.4486 15.6082 24.9287Z'*/}
            {/*      fill='#0D003B'*/}
            {/*    />*/}
            {/*  </svg>*/}

            {/*  <Text>Return to WTA Unlocked</Text>*/}
            {/*</Flex>*/}
          </Grid>
        </DrawerBody>

        <DrawerFooter flexDirection='column' gap='16px'>
          <ProfileButton />

          <Box>
            <MarketingForm />
            <Text fontSize='12px' fontWeight='400'>
              All prize winners will be contacted via the registered WTA
              unlocked contact details
            </Text>
          </Box>

          <LoginAndLogOut />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
