import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useKeycloak } from '@/providers/Keycloak.provider';
import {
  useLazyCheckEmailQuery,
  useLazyGetUserQuery,
  useLoginWithTokenMutation,
} from '@/services/api.service';

import { PageLoader } from '@/elements/Loaders/page-loader/index';

import { SearchParamsEnum } from '@/enum/searchParams.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useSettings } from '@/hooks/useSettings';

import { trackEvent } from '@/utils/analyticTracks';
import { TOKEN_LOGOUT } from '@/utils/constants';

export type kcUserInfo = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  preferred_username: string;
  sub: string;
};

export type UserInfo = {
  email: string;
  email_verified: boolean;
  name: string;
};

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const {
    value: jwt,
    setItem: setJWT,
    removeItem,
  } = useLocalStorage<string>(StorageKeysEnum.jwt);
  const [triggerCheckEmail] = useLazyCheckEmailQuery();
  const [triggerGetUser] = useLazyGetUserQuery();
  const keycloak = useKeycloak();
  const { setShouldUpdateProfile } = useSettings();
  const [loginWithToken, { isLoading }] = useLoginWithTokenMutation();

  const handleAuthentication = useCallback(async () => {
    const kcUserInfo = (await keycloak.loadUserInfo()) as kcUserInfo;

    const { isEmailFree } = await triggerCheckEmail({
      email: encodeURIComponent((kcUserInfo as UserInfo)?.email),
    }).unwrap();
    if (isEmailFree) {
      setShouldUpdateProfile(true);
    }

    if (!isEmailFree && !jwt) {
      searchParams.set(SearchParamsEnum.token, keycloak?.token || '');
      setSearchParams(searchParams);
    }

    window.xtremepush('set', 'user_id', kcUserInfo?.sub);

    trackEvent({
      location: 'Log In',
      action: 'User successfully logs in',
      event: 'login',
      user_id: kcUserInfo?.sub,
    });
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    jwt,
    keycloak,
    navigate,
    searchParams,
    setSearchParams,
    triggerCheckEmail,
    triggerGetUser,
  ]);

  keycloak.onAuthSuccess = handleAuthentication; // add handling after keycloak authentication;

  const { setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );

  const { value: token } = useLocalStorage<string>(StorageKeysEnum.kcToken);

  const urlToken = searchParams.get(SearchParamsEnum.token);

  const lang = useMemo(
    () => searchParams.get(SearchParamsEnum.lang),
    [searchParams],
  );

  const isUrlUser = useMemo(
    () => searchParams.get(SearchParamsEnum.user) === 'true',
    [searchParams],
  );

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang, () => setLang(lang));
      searchParams.delete(SearchParamsEnum.lang);
      setSearchParams(searchParams);
    }
  }, [i18n, lang, searchParams, setLang, setSearchParams]);

  const handleLogin = async (token: string) => {
    try {
      if (!token) return;

      const res = await loginWithToken({ token }).unwrap();
      setJWT(res.token);

      searchParams.delete(SearchParamsEnum.token);
      setSearchParams(searchParams);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info(e);
      // return toast('Something went wrong', ToastTypesEnum.error);
    }
  };

  useEffect(() => {
    if (urlToken && urlToken !== TOKEN_LOGOUT) {
      handleLogin(urlToken);
      return;
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlToken]);

  useEffect(() => {
    if (token && token === TOKEN_LOGOUT) {
      searchParams.delete(SearchParamsEnum.token);
      setSearchParams(searchParams);
      removeItem();
    }
    if ((token && token !== TOKEN_LOGOUT) || !jwt) {
      handleLogin(token);
      return;
    }
    // eslint-disable-next-line
  }, [token]);

  const { value: clickedGameURL, removeItem: removeClickedGameURL } =
    useLocalStorage<string>(StorageKeysEnum.clickedGameURL);
  const { value: kcToken } = useLocalStorage<string>(StorageKeysEnum.kcToken);

  useEffect(() => {
    if (clickedGameURL) {
      removeClickedGameURL();
      window.location.assign(
        `${clickedGameURL}/?token=${kcToken}&lang=${lang}`,
      );
      return;
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedGameURL]);

  useEffect(() => {
    if (isUrlUser && keycloak) {
      keycloak.onReady = () => keycloak.login();
    }
  }, [keycloak, isUrlUser]);

  return (
    <>
      {children}
      {isLoading && <PageLoader />}
    </>
  );
};
