import { forwardRef, RefObject, useMemo } from 'react';

import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputProps as ChakraInputProps,
  InputRightElement,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';

import { FormInputProps } from '@/elements/FormInput';
import FormLabel from '@/elements/FormLabel';

type InputProps = ChakraInputProps &
  FormInputProps & {
    clearFieldHandler?: () => void;
    error?: string | undefined;
    showClear?: boolean;
    containerProps?: FormControlProps;
    isSuccess?: boolean;
  };

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      clearFieldHandler,
      error,
      hasTooltip,
      label,
      showClear,
      tooltipText,
      variant = 'primary',
      containerProps,
      id,
      hasLeftElement,
      leftElement,
      hintText,
      isInvalid,
      isSuccess,
      ...rest
    },
    ref,
  ) => {
    const inputId = useMemo(() => id || `${Date.now()}-${Math.random()}`, [id]);
    const theme = useTheme();

    return (
      <FormControl
        id={inputId}
        isInvalid={isInvalid}
        isReadOnly={variant === 'readonly'}
        {...containerProps}
      >
        {label && (
          <FormLabel
            hasTooltip={hasTooltip}
            id={inputId}
            label={label}
            tooltipText={tooltipText}
          />
        )}

        {hintText && (
          <Text color='input.hint' fontSize='sm' fontWeight='500' mb='xs'>
            {hintText}
          </Text>
        )}

        <InputGroup>
          {hasLeftElement && leftElement && (
            <InputLeftElement
              color='input.cancel'
              fontSize='1.2em'
              pointerEvents='none'
            >
              {leftElement}
            </InputLeftElement>
          )}
          <ChakraInput
            id={inputId}
            ref={ref as RefObject<HTMLInputElement>}
            variant={variant}
            {...rest}
          />
          <InputRightElement>
            {showClear && (
              <MdCancel
                color={theme.colors.input.cancel}
                cursor='pointer'
                onClick={clearFieldHandler}
              />
            )}
            {isSuccess && (
              <Box ml='5px'>
                <BsFillCheckCircleFill fill='green' />
              </Box>
            )}
          </InputRightElement>
        </InputGroup>

        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  },
);

Input.displayName = 'Input';

export default Input;
