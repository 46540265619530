import { useCallback, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { kcUserInfo, UserInfo } from '@/providers/AuthProvider';
import { useKeycloak } from '@/providers/Keycloak.provider';
import { useLazyCheckEmailQuery } from '@/services/api.service';

import { RoutesEnum } from '@/enum/routes.enum';

import { useJWT } from '@/hooks/useJWT';
import { useSettings } from '@/hooks/useSettings';

const AUTH_PAGES: Array<string> = [RoutesEnum.PROFILE];

export const useAuth = () => {
  const { jwt } = useJWT();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { setShouldUpdateProfile } = useSettings();
  const keycloak = useKeycloak();

  const [triggerCheckEmail] = useLazyCheckEmailQuery();

  const checkAuth = useCallback((): Promise<boolean> => {
    return (async () => {
      const kcUserInfo = (await keycloak.loadUserInfo()) as kcUserInfo;

      const { isEmailFree } = await triggerCheckEmail({
        email: encodeURIComponent((kcUserInfo as UserInfo)?.email),
      }).unwrap();
      if (isEmailFree) {
        setShouldUpdateProfile(true);
      }

      return isEmailFree;
    })();
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNeedAuth = AUTH_PAGES.includes(pathname);

  useEffect(() => {
    if (isNeedAuth && !jwt) navigate(RoutesEnum.HOME);
  });

  return {
    checkAuth,
  };
};
