// import ReactPixel from 'react-facebook-pixel';
import { Helmet, HelmetProvider as Provider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/localization';

// import { META_PIXEL_TOKEN } from '@/utils/constants';

// ReactPixel.init(`${META_PIXEL_TOKEN}`);

// ReactPixel.pageView(); // For tracking page view

export const MetadataProvider = () => {
  const { t } = useTranslation();
  return (
    <Provider>
      <Helmet>
        <meta
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
          name='viewport'
        />
        <title>{t(tKeys.gamezone)}</title>
        <link href='https://fonts.googleapis.com' rel='preconnect' />
        <link href='https://fonts.gstatic.com' rel='preconnect' />
        <link
          href='https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'
          rel='stylesheet'
        />
      </Helmet>
    </Provider>
  );
};
