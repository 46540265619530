import { useSettings } from '@/hooks/useSettings';

import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

const Header = () => {
  const { isDesktop } = useSettings();

  return isDesktop ? <DesktopHeader /> : <MobileHeader />;
};

export default Header;
